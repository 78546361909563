<template>
  <div>
    <div class="search-bar">
      <a-form-model ref="searchRef" :model="searchForm" class="flex">
        <a-form-model-item class="flex mb-1" prop="name" label="名称">
          <a-input style="width:100%" v-model="searchForm.name" placeholder="请输入名称" />
        </a-form-model-item>
        <a-form-model-item class="flex mb-1 ml-4" prop="delivery_region_list" label="覆盖区域">
          <region-tree-select style="width:300px;" @getNodes="getSelectNodes" :value.sync="delivery_region_list" />
        </a-form-model-item>
        <a-form-model-item class="flex mb-1 ml-4" prop="status" label="状态">
          <a-select style="width:100px;" v-model="searchForm.status" @change="initData">
            <a-select-option :value="0">全部</a-select-option>
            <a-select-option :value="1">正常</a-select-option>
            <a-select-option :value="2">禁用</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item class="flex mb-1 ml-6">
          <a-button class="mr-2" @click="resetForm">重置</a-button>
          <a-button type="primary" html-type="submit" @click="initData">搜索</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>

    <base-table ref="tableRef" rowKey="id" :columnsData="columns" :tableData="tableData">
      <template #toolbar>
        <div class="flex justify-between">
          <h4 class="text-lg font-bold text-black">特殊地区固定运费</h4>
          <a-button type="primary" @click="handlerAdd">新增</a-button>
        </div>
      </template>
      <template #status="{text}">
        <a-tag v-if="text==1" class="mr-0" color="green">正常</a-tag>
        <a-tag v-if="text==2" class="mr-0" color="red">停用</a-tag>
      </template>
      <template #operation="{record}">
        <a-button class="p-0 ml-2 mr-2" type="link" @click="handlerEdit(record)">编辑</a-button>
        <a-popconfirm v-if="record.status == 1" placement="leftTop" 
          @confirm="handlerUpdateStatus(record, 2)">
          <template #title>您确定要停用该规则吗？</template>
          <a-button class="p-0 ml-2 mr-2" style="color:red;" type="link">停用</a-button>
        </a-popconfirm>
        <a-popconfirm v-if="record.status == 2" placement="leftTop" 
          @confirm="handlerUpdateStatus(record, 1)">
          <template #title>您确定要恢复该规则吗？</template>
          <a-button class="p-0 ml-2 mr-2" type="link">恢复</a-button>
        </a-popconfirm>
      </template>
    </base-table>
    <edit-freight-modal v-if="isModalState.show" :showState.sync="isModalState" />
  </div>
</template>

<script>
import EditFreightModal from "./components/edit-freight-modal.vue"
import RegionTreeSelect from "@/components/region-tree-select"
import { getExpressFreightList, updateStatusExpressFreight } from "@/api/warehouse"

export default {
  components: {
    EditFreightModal,
    RegionTreeSelect
  },
  data() {
    return {
      isModalState: {
        show: false,
        type: "add",
      },
      wrapperCol: { span: 20 },
      labelCol: { span: 4 },
      columns: [
        {
          title: '名称',
          dataIndex: 'name',
          align: 'center',
          width: 150
        },
        {
          title: '覆盖区域',
          dataIndex: 'delivery_region',
        },
        {
          title: '固定运费',
          dataIndex: 'freight_amount',
          align: 'center',
          width: 100
        },
        {
          title: '状态',
          dataIndex: 'status',
          width: 80,
          align: 'center',
          slots: {
            customRender: 'status'
          }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          align: 'center',
          width: 120,
          slots: {
            customRender: 'operation'
          }
        },
      ],
      tableData: [],

      searchForm: {
        name: "",
        status: 1,
        delivery_region_data: "",
      },
      delivery_region_list: [],
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      const params = {
        ...this.searchForm,
        delivery_region_data: this.delivery_region_list ? JSON.stringify(this.delivery_region_list) : ""
      }
      const { data, code } = await getExpressFreightList(params)
      if (code === 0) {
        this.tableData = data.list
      }
    },
    resetForm() {
      this.delivery_region_list = null
      this.$refs.searchRef.resetFields()
      this.initData()
    },
    
    handlerAdd() {
      this.isModalState = {
        show: true,
        type: "add",
      }
    },
    handlerEdit(row) {
      this.isModalState = {
        show: true,
        type: "edit",
        data: row,
      }
    },

    getSelectNodes(nodes){
      this.delivery_region_list = nodes
    },

    // 修改仓库状态
    handlerUpdateStatus(row, new_status) {
      updateStatusExpressFreight({
        id: row.id,
        status: new_status,
      }).then((res) => {
        if (res.code == 0){
          const msg = new_status==2 ? "已停用" : "已恢复"
          this.$message.success(msg)
        }
        this.initData()
      })
    },

  },
}
</script>
