<template>
  <a-modal
    :visible.sync="showState.show"
    :title="showState.type === 'edit' ? '修改地区运费' : '新增地区运费'"
    destroyOnClose
    width="40%"
    @ok="handleOk"
    @cancel="handlerCancel"
  >
    <div class="modal-search-bar">
      <a-form-model
        layout="horizontal"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        ref="searchRef"
        :model="addForm"
      >
        <a-form-model-item prop="name" label="名称">
          <a-input allowClear v-model="addForm.name" placeholder="运费规则名称"></a-input>
        </a-form-model-item>
        <a-form-model-item label="覆盖区域">
          <region-tree-select @getNodes="getSelectNodes" :value.sync="region_tree_data" />
        </a-form-model-item>
        <a-form-model-item prop="name" label="固定运费">
          <a-input-number style="width:200px;" placeholder="固定运费" 
            v-model="addForm.freight_amount" :min="0" :step="0.01" />
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import RegionTreeSelect from "@/components/region-tree-select/index"
import { addExpressFreight, updateExpressFreight } from "@/api/warehouse"

export default {
  components: { RegionTreeSelect },
  props: {
    showState: {
      type: Object,
    },
  },
  data() {
    return {
      wrapperCol: { span: 20 },
      labelCol: { span: 4},
      addForm: {
        id: "",
        name: "",
        delivery_region_data: "",
      },
      region_tree_data: []
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      if (this.showState.type === "edit") {
        this.addForm = {
          ...this.showState.data
        }
        const treeData = []
        this.showState.data.delivery_region_adcode.map((province) => {
          const provinceData = {
            type: 1,
            adcode: province.province_adcode,
            selectChildren: [],
          }
          treeData.push(provinceData)

          if (province.city_list.length > 0) {
            province.city_list.map((city) => {
              const cityData = {
                type: 2,
                adcode: city.city_adcode,
                selectChildren: []
              }
              provinceData.selectChildren.push(cityData)
              if (city.area_list.length > 0) {
                city.area_list.map((area) => {
                  const areaData = {
                    type: 3,
                    adcode: area.area_adcode,
                    selectChildren: []
                  }
                  cityData.selectChildren.push(areaData)
                })
              }
            })
          }
        })
        this.region_tree_data = treeData
      }
    },
    // 保存
    handleOk() {
      const params = {
        ...this.addForm,
        delivery_region_data: JSON.stringify(this.region_tree_data)
      }

      if (this.showState.type === "edit") {
        updateExpressFreight(params).then((res) => {
          if (res.code === 0) {
            this.$message.success("编辑成功！")
            this.showState.show = false
            this.$parent.initData()
          }
        })
      } else {
        addExpressFreight(params).then((res) => {
          if (res.code === 0) {
            this.$message.success("新增成功！")
            this.showState.show = false
            this.$parent.initData()
          }
        })
      }
    },

    getSelectNodes(nodes){
      this.region_tree_data = nodes
    },
    resetForm() {
      this.$refs.searchRef.resetFields()
    },
    handlerCancel() {
      this.showState.show = false
    },
  },
}
</script>

<style>
.ant-modal-body {
  position: relative;
}
</style>